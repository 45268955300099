import React from 'react';
import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import {graphql, useStaticQuery} from "gatsby";
import Title from "../../components/title/Title";
import GenericHeader from "../../components/header_section/GenericHeader";
import Container from "../../components/layouts/Container";
import RowItem from "../../components/layouts/RowItem";
import FooterDivider from "../../components/FooterDivider";
import cn from "classnames";
import CPLink from "../../components/button/CPLink";
import styled from "styled-components";

const PostsTitle = styled(Title)`
    margin-top: ${20 / 16}rem;
    margin-bottom: ${27 / 16}rem;
`

const ReadMore = styled(CPLink)`
    max-width: ${169 / 16}rem;
    letter-spacing: 0;
    font-size: ${12.48 / 16}rem;
    padding: ${14 / 16}rem ${45 / 16}rem;
    text-align: center;
    width: auto !important;
    white-space: nowrap;
    margin-top: ${20 / 16}rem;
    margin-bottom: ${40 / 16}rem;
`

export default () => {
  const data = useStaticQuery(graphql`
    query BlogIndex {
      lastPost: allMarkdownRemark(limit: 1, sort: { order: DESC, fields: [frontmatter___date] }, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
              author {
                name
                img {
                  childImageSharp {
                    fixed(width: 40, height: 40) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 3840, quality: 100, srcSetBreakpoints: [768, 1366, 1920, 3840]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }

      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              duration
              description
              tags
              date(formatString: "MMMM DD, YYYY")
              author {
                name
                img {
                  childImageSharp {
                    fixed(width: 40, height: 40) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 504, maxHeight: 354, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lastPost = data.lastPost.edges[0].node;
  return (
    <Layout
      meta_image={lastPost.frontmatter.featuredimage.childImageSharp.fluid.src}
      meta_image_width={lastPost.frontmatter.featuredimage.childImageSharp.fluid.presentationWidth}
      meta_image_height={lastPost.frontmatter.featuredimage.childImageSharp.fluid.presentationHeight}
      showSubscribe={true}
      local_title={'Blog - Cypress'}
      hasBackgroundColor={true}
      meta_keyword={`it blog, best it blogs, tech blog, top it blogs, it consulting blog, it tech blog, it company blog, it blogs to follow, it works blog`}
      meta_description={`Discover Cypress’s IT blog and get familiar with various technological articles related to mobile apps, their importance, development process, and even more.`}>
      <GenericHeader title={lastPost.frontmatter.title}
                     subtitle={lastPost.frontmatter.description}
                     backgroundColor={'#FFFFFF00'}
                     action={<><ReadMore to={lastPost.fields.slug} className={cn('white is-borderless')}>READ
                       MORE</ReadMore><br/><br/></>}
                     backgroundImage={lastPost.frontmatter.featuredimage}/>
      <Container>
        <RowItem>
          <PostsTitle>
            Posts
          </PostsTitle>
        </RowItem>
      </Container>
      <BlogRoll posts={data.allMarkdownRemark.edges}/>
      <FooterDivider marginTop={97} marginBottom={70}/>
      <br/>
    </Layout>
  );
}
